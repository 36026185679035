import Vue from 'vue'
import App from './App.vue'

import VueRouter from 'vue-router'
import axios from 'axios'
import VueSimpleAlert from 'vue-simple-alert'
import VueCryptojs from 'vue-cryptojs'
import Loading from "vue-loading-overlay"
import 'vue-loading-overlay/dist/vue-loading.css'

import Demand from './components/Demand'
import Sales from './components/Sales'
import Saleswc_old from './components/Saleswc_old'
import Saleswc from './components/Saleswc'
import Purchases from './components/Purchases'
import Purchaseswc from './components/Purchaseswc'
import PageNotFound from './components/PageNotFound'
import Login from './components/Login'
import ForgotPassword from './components/ForgotPassword'
import LedgersSale from './components/LedgersSale'
import LedgersPurchase from './components/LedgersPurchase'
import ItemMaster from './components/ItemMaster'
import AppSettings from './components/AppSettings'
import Profile from './components/Profile'
import Reports from './components/Reports'
import DayBook from './components/DayBook'
import ChangePassword from './components/ChangePassword'
import BackupData from './components/BackupData'
import RestoreData from './components/RestoreData'
import AccountReport from './components/AccountReport'
import ItemReport from './components/ItemReport'
import BrandReport from './components/BrandReport'
import SaleReport from './components/SaleReport'
import StockReport from './components/StockReport'
import PurchaseReport from './components/PurchaseReport'
import Test from './components/Test'




Vue.use(axios)
Vue.use(VueRouter)
Vue.use(VueSimpleAlert)
Vue.use(Loading)
Vue.use(VueCryptojs)


const routes = [

    { path: '/', component: Saleswc, meta: { requiresAuth: true } },
    { path: '/test', component: Test, meta: { requiresAuth: true } },
    { path: '/sale', component: Sales, meta: { requiresAuth: true } },
    { path: '/demand', component: Demand, meta: { requiresAuth: true } },
    { path: '/salewc', component: Saleswc, meta: { requiresAuth: true } },
    { path: '/salewc_old', component: Saleswc_old, meta: { requiresAuth: true } },
    { path: '/purchase', component: Purchases, meta: { requiresAuth: true } },
    { path: '/purchasewc', component: Purchaseswc, meta: { requiresAuth: true } },
    { path: '/login', component: Login, meta: { noMenu: true, noHeader: true } },
    { path: '/forgotpassword', component: ForgotPassword, meta: { noMenu: true, noHeader: true } },
    { path: '/settings', component: AppSettings, meta: { requiresAuth: true } },
    { path: '/accountssale', component: LedgersSale, meta: { requiresAuth: true } },
    { path: '/accountspurchase', component: LedgersPurchase, meta: { requiresAuth: true } },
    { path: '/itemmaster', component: ItemMaster, meta: { requiresAuth: true } },
    { path: '/changepassword', component: ChangePassword, meta: { requiresAuth: true } },
    { path: '/profile', component: Profile, meta: { requiresAuth: true } },
    { path: '/reports', component: Reports, meta: { requiresAuth: true } },
    { path: '/daybook', component: DayBook, meta: { requiresAuth: true } },
    { path: '/backupdata', component: BackupData, meta: { requiresAuth: true } },
    { path: '/restoredata', component: RestoreData, meta: { requiresAuth: true } },
    { path: '/accountreport', component: AccountReport, meta: { requiresAuth: true } },
    { path: '/itemreport', component: ItemReport, meta: { requiresAuth: true } },
    { path: '/brandreport', component: BrandReport, meta: { requiresAuth: true } },
    { path: '/salereport', component: SaleReport, meta: { requiresAuth: true } },
    { path: '/stockreport', component: StockReport, meta: { requiresAuth: true } },
    { path: '/purchasereport', component: PurchaseReport, meta: { requiresAuth: true } },

    { path: '/404', component: PageNotFound },
    { path: '/:NotFound(.*)*', redirect: '/404' }
]


const router = new VueRouter({
    routes,
    mode: 'history'
});

router.beforeEach((to, from, next) => {
    if (to.matched.some(record => record.meta.requiresAuth)) {
        if (!localStorage.getItem('ul')) {
            next({ path: '/login' })
        } else {
            next()
        }
    } else {
        if (localStorage.getItem('ul') && to.path == '/') {
            next({ path: '/salewc' })
        } else {
            next()
        }
    }
})

//global variables
Vue.prototype.$registerto = 'Doon Proteins'
Vue.prototype.$df = axios;
Vue.prototype.$au = 'https://dpapi.masswayshost.in/';
// Vue.prototype.$au = 'http://diapi.com/';

Vue.prototype.$ul = 'ul';
Vue.prototype.$ud = 'ud';
Vue.prototype.$ut = 'ut';

Vue.config.productionTip = false

Vue.mixin({
    methods: {
        enc(v) {
            return this.CryptoJS.AES.encrypt(v, '123').toString();
        },
        dec(v) {
            return this.CryptoJS.AES.decrypt(v, '123').toString(this.CryptoJS.enc.Utf8);
        }
    }
})


new Vue({
    router: router,
    render: h => h(App),
}).$mount('#app')